import 'vanilla-cookieconsent';

const initCookieNotice = () => {
  const lang = document.documentElement.lang.slice(0, 2);
  // eslint-disable-next-line no-undef
  const cookieConsent = initCookieConsent();

  cookieConsent.run({
    current_lang: lang,
    autoclear_cookies: true,
    page_scripts: false,
    cookie_domain: window.location.hostname,
    cookie_path: window.location.pathname,
    cookie_name: 'cc_cookie_nbp_pl',

    gui_options: {
      consent_modal: {
        layout: 'box',               // box/cloud/bar
        position: 'bottom right',     // bottom/middle/top + left/right/center
        transition: 'slide',           // zoom/slide
        swap_buttons: false            // enable to invert buttons
      },
    },

    languages: {
      'pl': {
        consent_modal: {
          title: 'Cenimy Twoją prywatność',
          description: 'Używamy plików cookie, aby poprawić komfort przeglądania, wyświetlać spersonalizowane' +
            ' reklamy lub treści oraz analizować nasz ruch. Klikając „Akceptuj”, wyrażasz zgodę na używanie' +
            ' przez nas plików cookie. Aby się dowiedzieć więcej o technologii cookies, proszę kliknąć tutaj: ' +
            '<a href="/o-nbp/kontakt/regulaminy/" class="cc-link">Polityka Prywatności NBP</a>. ' +
            'Aby móc przeglądać zawartość, należy zaakceptować cookies z tej strony.',
          primary_btn: {
            text: 'Akceptuj',
            role: 'accept_all',
          },
        },
        settings_modal: {
          title: 'Ustawienia',
          save_settings_btn: 'Zapisz ustawienia',
          accept_all_btn: 'Akceptuj wszystkie',
          blocks: []
        }
      },
      'en': {
        consent_modal: {
          title: 'We care about your privacy',
          description: 'We use cookies to improve your browsing experience, display personalized ads or content, and' +
            ' analyze our traffic. By clicking "Accept", you consent to our use of cookies. ' +
            'To find out more about the cookie technology, please click here: ' +
            '<a href="en/about-nbp/contact/nbps-regulations/" class="cc-link">NBP Privacy Policy</a>. ' +
            'In order to browse through the content, it is necessary to accept cookies from this site.',
          primary_btn: {
            text: 'Accept',
            role: 'accept_all',
          },
        },
        settings_modal: {
          title: 'Settings',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          blocks: []
        }
      }
    }
  });
};

export { initCookieNotice };

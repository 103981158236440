export const parentPageActive = () => {
  // Get all menu items
  const menuItems = document.querySelectorAll('.menu-item');

  if (menuItems) {
    // Loop through each menu item
    menuItems.forEach(function (menuItem) {
      // Check if the menu item has either current-menu-ancestor or current-menu-parent classes
      if (menuItem.classList.contains('current-menu-ancestor') || menuItem.classList.contains('current-menu-parent')) {
        menuItem.classList.add('nav-item-active');
        // Find .nav-link elements inside the current menu item
        const navLinks = menuItem.querySelectorAll('.nav-link');
        const navDropdownMenu = menuItem.querySelectorAll('.dropdown-menu');

        // Open dropdown menu
        navDropdownMenu.forEach(function (dropdownMenu) {
          const dropdown = new bootstrap.Dropdown(dropdownMenu)
          dropdown.show();
        });

        // Add the "show" class to each .nav-link element
        navLinks.forEach(function (navLink) {
          navLink.classList.add('show');
        });
      }
    });
  }
};

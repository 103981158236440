// -- Vendors -- //
import { initLazyLoad } from './frontend/vendors/initLazyLoad';
import { initOwlCarousel } from './frontend/vendors/initOwlCarousel';
import { initCookieNotice } from './frontend/vendors/initCookieNotice';

// -- Components -- //
import { initOffcanvasToggling } from './frontend/components/header/initOffcanvasToggling';
import { initChangeContrast } from './frontend/components/contrast-switcher/ChangeContrast';
import { initCarouselProgressBar } from './frontend/components/carousel/carouselProgressBar';
import { initSwipe } from './frontend/components/carousel/initSwipe';
import { initHeaderCarousel } from './frontend/components/carousel/initHeaderCarousel';
import { initEqualHeight } from './frontend/components/carousel/initEqualHeight';
import { initExtendAccordion } from './frontend/components/accordion/extendAccordion';
import { initShowMoreItems } from './frontend/components/header/showMoreItems';
import { initNavExpandClass } from './frontend/components/header/navExpandClass';
import './frontend/components/validation/init';
import './frontend/components/pagination/initPagination';
import { parentPageActive } from './frontend/components/header/parentPageActive';

// -- Invokes on document ready -- //
$(function () {
  // -- Vendors
  initLazyLoad?.();
  initOwlCarousel?.();
  initCookieNotice?.();

  // -- Layout
  initExtendAccordion?.();
  initCarouselProgressBar?.();
  initSwipe?.();
  initHeaderCarousel?.();
  initEqualHeight?.();
  parentPageActive?.();

  // -- Components
  initOffcanvasToggling?.();
  initNavExpandClass?.();
  initShowMoreItems?.();
  initChangeContrast?.();

  // handle printing when the link is clicked
  const printPageToggler = document.getElementById('print-page');
  if (printPageToggler) {
    printPageToggler.addEventListener('click', function () {
      window.print();
    });
  }
});

import 'jquery-touchswipe';

const initHeaderCarousel = () => {
  const carouselElement = document.getElementById('carouselSlidesOnly');

  if (carouselElement !== null) {
    // eslint-disable-next-line no-undef
    const carousel = new bootstrap.Carousel(carouselElement, {
      interval: 10000,
      ride: 'carousel',
      touch: false,
    });

    $('#carouselSlidesOnly').swipe({
      swipe: function (event, direction) {
        if (direction == 'left') carousel.next();
        if (direction == 'right') carousel.prev();
      },
      allowPageScroll: 'vertical'
    });
  }
};

export { initHeaderCarousel };
